import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertController, ModalController } from '@ionic/angular';
import { Camera } from '@capacitor/camera';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    public Version = '3.4.17';
    // zie ook 2x versie in android/app/build.gradle
    // en ios/App/App.xcodeproj/project.pbxproj

    public latestVersion = '';
    public apkurl = '';
    public apkDownload = false;
    public MyPlatform = 'android'; // 'android'; // 'ios';
    public App = 'BAR3';
    public devID = '';
    public devInfo = '';

    public baseurl = 'https://cloud.lisaas.com/lipronics/';

    public isLoggedIn = false;
    public compname: string;
    public username: string;
    public password: string;
    public usrvalue: string;
    public usrlevel: number;
    public session: string;
    public uuid: string;
    public var: string[];
    public modules: string[];
    public language = 'nl';
    public txt: string[]; // vertalingen worden hierin geladen

    public optHideScanBtn: boolean;
    public optAfbV2: boolean;
    public startPage = '/home';
    public optInclBtw: boolean = false;

    public currArtNr: string;
    public currProjNr: string;
    public currBedr: string;
    public currObject: string;

    constructor(
        private snackBar: MatSnackBar,
        public alertController: AlertController,
        public modalController: ModalController,
    ) { }

    getvar(varname: string, defaultValue='') {
        if (this.var && varname in this.var) {
            return this.var[varname];
        } else {
            return defaultValue;
        }
    }

    getmod(module: string) {
        if (this.modules) {
            if (this.modules[module]) {
                return this.modules[module];
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    async showPopoverCameraRights() {
        const alert = await this.alertController.create({
            header: 'Camera permissions',
            message: 'Please allow the camera and photos permissions to use this feature.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Allow',
                    handler: () => {
                    }
                }
            ]
        });
        await alert.present();
    }

    async startCamera() {
        if (this.MyPlatform === 'web') {
            console.log('Camera not available on web platform');
            this.snackBar.open('Geen camera toegang via web versie.', 'OK', {
                duration: 1000
            });
        return false;
        }
        const permissions = await Camera.requestPermissions();
        if(permissions.photos === 'denied' || permissions.camera === 'denied') {
            //Popover asking them to click `Allow` on the native permission dialog
            await this.showPopoverCameraRights();
            const permissions = await Camera.requestPermissions();
            if (permissions.photos === 'denied' || permissions.camera === 'denied') {
                console.log('Camera permission not granted');
                this.snackBar.open('Geen rechten voor camera gebruik.', 'OK', {
                    duration: 1000
                });
                return false;
            }
        }
        return true;
    }

}
